import Glide from '@glidejs/glide'

initializeCarousels();

// We need to wait for the KidAnalytics instance on the window to load before firing off our analtyics.
window.addEventListener('load', initializeBannerSetsAnalytics);

function initializeCarousels() {
  const carousels = document.querySelectorAll('.carousel');

  carousels.forEach(carousel => initializeCarousel(carousel));
}

function initializeCarousel(carouselElement) {
  new Glide(
    carouselElement,
    {
      perView: 6.5,
      gap: 12, // This matches the gutters in the grid banner sets. Make sure both sync up.
      bound: true,
      breakpoints: {
        1100: {
          perView: 5.5
        },
        768: {
          perView: 3.5
        },
        550: {
          perView: 2.5
        }
      }
    }
  ).mount()
}

function initializeBannerSetsAnalytics() {
  const bannerSets = document.querySelectorAll("[data-banner-set-name]");

  for (let i = 0; i < bannerSets.length; i++) {
    const bannerSet = bannerSets[i];
    const bannerSetPosition = i + 1;
    const bannerSetName = bannerSet.dataset.bannerSetName;

    initializeBannerSetAnalytics(bannerSet, bannerSetName, bannerSetPosition);
  }

  initializeSearchHeroAnalytics();
}

function initializeSearchHeroAnalytics() {
  const searchHero = document.querySelector(".banner-set__search-hero");
  if (searchHero) {
    searchHero.addEventListener("submit", (event) => {
      KidAnalytics.trackSearch();
    });
  }
}

function initializeBannerSetAnalytics(bannerSet, bannerSetName, bannerSetPosition) {
  initializeBannerSetBannersAnalytics(bannerSet, bannerSetName, bannerSetPosition);
  initializeBannerSetCTAAnaltyics(bannerSet, bannerSetName, bannerSetPosition);
}

function initializeBannerSetBannersAnalytics(bannerSet, bannerSetName, bannerSetPosition) {
  const banners = bannerSet.querySelectorAll("[data-banner-title]");

  for (let i = 0; i < banners.length; i++) {
    const banner = banners[i];
    const bannerName = banner.dataset.bannerTitle;
    const bannerId = banner.id;

    window.KidAnalytics.setUpTrackBannerSetClick(
      `#${bannerId} a`,
      bannerSetName,
      bannerSetPosition,
      bannerName
    )
  }
}

function initializeBannerSetCTAAnaltyics(bannerSet, bannerSetName, bannerSetPosition) {
  window.KidAnalytics.setUpTrackBannerSetClick(
    `#${bannerSet.id} .js-banner-set-cta`,
    bannerSetName,
    bannerSetPosition,
    "CTA"
  )
}
